import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import EnergyAppCard, { EnergyAppCardProps } from '../components/EnergyAppCard';
import Page from '../components/Page';

import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import BalanceRoundedIcon from '@mui/icons-material/BalanceRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';
import CableRoundedIcon from '@mui/icons-material/CableRounded';
import ElectricMeterRoundedIcon from '@mui/icons-material/ElectricMeterRounded';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';

const energyIntegrations: EnergyAppCardProps[] = [
  {
    title: 'Schedule Management',
    category: 'Market Access',
    type: 'Official',
    icon: <AnalyticsRoundedIcon fontSize="large" />,
    isUnderDevelopment: false,
    powerupConnectors: [
      { name: 'Amprion', added: false, logotype: 'amprion.png'},
      { name: '50 Hertz', added: false,logotype: '50hertz.jpg'},
      { name: 'TenneT', added: false, logotype: 'tennet.jpg'},
      { name: 'TransnetBW', added: false, logotype: 'transnetbw.png'},
    ],
  },
  {
    title: 'Anciliary Management',
    category: 'Market Access',
    type: 'Official',
    icon: <BalanceRoundedIcon fontSize="large" />,
    isUnderDevelopment: true,
    powerupConnectors: []
  },
  {
    title: 'Weather Data',
    category: 'Weather',
    type: 'Partner',
    icon: <WbSunnyRoundedIcon fontSize="large" />,
    isUnderDevelopment: false,
    powerupConnectors: []
  },
  {
    title: 'Weather Forecasts',
    category: 'Weather',
    type: 'Partner',
    icon: <ThermostatRoundedIcon fontSize="large" />,
    isUnderDevelopment: false,
    powerupConnectors: []
  },
  {
    title: 'Grid Congestion Data',
    category: 'Grid',
    type: 'Partner',
    icon: <CableRoundedIcon fontSize="large" />,
    isUnderDevelopment: true,
    powerupConnectors: []
  },
  {
    title: 'Smart Meter Data',
    category: 'Grid',
    type: 'Partner',
    icon: <ElectricMeterRoundedIcon fontSize="large" />,
    isUnderDevelopment: false,
    powerupConnectors: []
  },
  {
    title: 'Power Tariffs',
    category: 'Grid',
    type: 'Partner',
    icon: <SavingsRoundedIcon fontSize="large" />,
    isUnderDevelopment: false,
    powerupConnectors: []
  },
];

const IntegrationStorePage = () => {
  const [filterCategory, setFilterCategory] = useState<string>('');

  // Filter logic
  const filteredIntegrations = energyIntegrations.filter((card) =>
    filterCategory ? card.category === filterCategory : true
  );

  return (
    <Page>
      <Box sx={{ width: '100%', maxWidth: '1700px', mx: 'auto' }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
          Energy Integration Store
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, mb: 3, alignItems: 'center' }}>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              label="Category"
            >
              <MenuItem value="">All Categories</MenuItem>
              <MenuItem value="Market Access">Market Access</MenuItem>
              <MenuItem value="Weather">Weather</MenuItem>
              <MenuItem value="Grid">Grid</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Column layout */}
        <Box
          sx={{
            columnCount: { xs: 1, sm: 2, md: 3 }, // Number of columns based on screen size
            columnGap: '16px', // Gap between columns
          }}
        >
          {filteredIntegrations.map((card, index) => (
            <Box
              key={index}
              sx={{
                display: 'inline-block', // Ensures proper wrapping inside columns
                width: '100%', // Ensures cards fit their column
                mb: 2, // Adds spacing between rows
              }}
            >
              <EnergyAppCard {...card} />
            </Box>
          ))}
        </Box>
      </Box>
    </Page>
  );
};

export default IntegrationStorePage;
