import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Page from '../../components/Page';

const HomePage = () => {

  return (
    <Page>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Under Development
        </Typography>
      </Box>
    </Page>
  )
}

export default HomePage;
