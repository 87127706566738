import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#f5f5f9', // Dark or light mode
    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.87)', // Text color based on mode
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#dadde9'}`, // Border color
    padding: theme.spacing(2),
  },
}));

export default HtmlTooltip;
