import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { CheckCircle, AddCircleOutline, ConstructionOutlined } from '@mui/icons-material';

export type Connector = {
  name: string; // Connector name
  logotype: string; // URL to the connector logotype
  added: boolean; // Whether the connector has been added
};

export type EnergyAppCardProps = {
  title: string;
  category: string;
  type: string;
  icon: React.ReactNode; // Icon for the energy integration
  powerupConnectors: Connector[];
  isUnderDevelopment?: boolean;
};

export default function EnergyAppCard({
  title,
  category,
  type,
  icon,
  powerupConnectors,
}: EnergyAppCardProps) {
  const [connectors, setConnectors] = useState(powerupConnectors);

  const handleAddConnector = (name: string) => {
    setConnectors((prev) =>
      prev.map((connector) =>
        connector.name === name ? { ...connector, added: true } : connector
      )
    );
  };

  return (
    <Card sx={{ position: 'relative', height: '100%', flexGrow: 1, padding: 2 }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {icon}
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="textSecondary">
            {category}
          </Typography>
        </Box>
      </CardContent>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Energy Connectors
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {/* Check if there are no connectors */}
              {connectors.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="span"
                    >
                      Under Development
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                connectors.map((connector) => (
                  <TableRow key={connector.name}>
                    {/* Logotype */}
                    <TableCell>
                      <Box
                        component="img"
                        src={connector.logotype}
                        alt={connector.name}
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    </TableCell>
                    {/* Connector Name */}
                    <TableCell>{connector.name}</TableCell>
                    {/* Action Icon */}
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleAddConnector(connector.name)}
                        disabled={connector.added} // Disable the button if already added
                      >
                        {connector.added ? (
                          <CheckCircle sx={{ color: 'success.main' }} />
                        ) : (
                          <AddCircleOutline sx={{ color: 'primary.main' }} />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
