import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Divider from '@mui/material/Divider';

const mainListItems = [
  { text: 'Schedule Debugger', path: '/schedule-management/debugger' },
  { text: 'Schedule Logs', path: '/schedule-management/logs' },
];

const secondaryListItems = [
  { text: 'Settings', icon: <SettingsRoundedIcon />, path: '/settings' },
  { text: 'Documentation', icon: <HelpRoundedIcon />, path: 'https://api.app.engrate.io/redoc' },
];

export default function MenuContent() {
  const location = useLocation();

  // Determine if any of the nested pages is selected
  const isNestedPageSelected = mainListItems.some((item) => location.pathname === item.path);

  // Collapse state depends on whether a nested page is selected
  const [open, setOpen] = React.useState(isNestedPageSelected);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        <Typography
          variant="overline"
          gutterBottom
          sx={{
            display: 'block',
            color: 'text.secondary',
          }}
        >
          Active Energy Products
        </Typography>

        {/* Header with Logo */}
        <ListItemButton
          onClick={handleToggle}
          sx={{
            px: 1,
            py: 0.5,
            width: '100%',
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <AnalyticsRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Schedule Management" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        {/* Nested Items */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 0 }}>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                pl: 3,
                display: 'block',
                color: 'text.secondary',
              }}
            >
              Developer Tools
            </Typography>
            <Divider variant="middle" />
            {mainListItems.map((item, index) => (
              <ListItem key={index}>
                <ListItemButton
                  selected={location.pathname === item.path}
                  component={Link}
                  to={item.path}
                >
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              selected={location.pathname === item.path}
              component={Link}
              to={item.path}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
