import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StatusChip from '../../components/StatusChip';

const StatusTooltipContent: React.FC = () => {
  const statuses = [
    { value: 'ingested', description: 'The schedule undergoes formal validation for XML structure and schema compliance. The schedule has been ingested and stored in the Engrate platform.' },
    { value: 'submitted', description: 'The schedule has been submitted for approval to the TSO.' },
    { value: 'pending', description: 'The schedule has been recieved by the TSO and acknowledged. (ACK)' },
    { value: 'accepted (preliminary)', description: 'The schedule has been preliminarily accepted. (intermediate, iCNF)' },
    { value: 'accepted (final)', description: 'The schedule has been finally accepted. (day ahead, dCNF)' },
    { value: 'settled', description: 'The schedule has been settled and finalized. (final, fCNF)' },
    { value: 'rejected', description: 'The schedule has been rejected. See logs for details.' },
  ];

  return (
    <div style={{ textAlign: 'left' }}>
    <Typography color="inherit" variant="subtitle2" gutterBottom>
      Status Information
    </Typography>
    <Typography variant="body2" gutterBottom>
      The table below outlines the synchronous steps and detailed descriptions of each status:
    </Typography>
    <Table size="small" style={{ width: '100%' }}>
      <TableBody>
      {statuses.map((status) => (
        <TableRow key={status.value}>
        <TableCell style={{ padding: '4px', width: '20%' }}>
          <StatusChip status={status.value as any} />
        </TableCell>
        <TableCell style={{ padding: '4px' }}>
          <Typography variant="body2">{status.description}</Typography>
        </TableCell>
        </TableRow>
      ))}
      </TableBody>
    </Table>
    </div>
  );
  };

  export default StatusTooltipContent;
