import React from 'react';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

type Status = 'ingested' | 'accepted' | 'submitted' | 'pending' | 'partially_accepted' | 'settled' | 'rejected' | string;

interface StatusChipProps {
  status: Status;
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const icons: { [key in Status]?: JSX.Element } = {
    rejected: <CancelIcon />,
    ingested: <HourglassEmptyIcon />,
    submitted: <CloudUploadIcon />,
    pending: <QueryBuilderIcon />,
    partially_accepted: <PublishedWithChangesIcon />,
    accepted: <CheckCircleIcon />,
    settled: <DoneAllIcon />,
    'accepted (preliminary)': <PublishedWithChangesIcon />,
    'accepted (final)': <CheckIcon />,
  };

  const colors: { [key in Status]?: 'warning' | 'secondary' | 'success' | 'primary' | 'default' | 'error' } = {
    rejected: 'error',
    ingested: 'default',
    submitted: 'primary',
    pending: 'secondary',
    partially_accepted: 'warning',
    accepted: 'success',
    settled: 'success',
    'accepted (preliminary)': 'warning',
    'accepted (final)': 'success',
  };

  return (
    <Chip
      variant="outlined"
      size="small"
      icon={icons[status] || <CheckIcon />}
      label={status.replace('_', ' ')}
      color={colors[status] || 'default'}
    />
  );
};

export default StatusChip;

